<template>
  <div class="list-products-container">
    <div class="p-2 d-flex flex-wrap justify-content-between align-items-start">
      <div class="mt-1 mr-1 d-flex align-items-center">
        <label class="mr-1">Поиск:</label>
        <b-form-input
            placeholder="Поиск"
            type="text"
            class="d-inline-block"
            @input="function(val) {currentFind = val; currentPage = 1; loadNomenclaturesFiltered()}"

        />
      </div>
      <div class="d-flex flex-wrap">
        <div class="mt-1 mr-1 d-flex align-items-center">
          <label class="mr-1">Бренд:</label>
          <b-form-select
              @change="function(val) {selectBrand = val; currentPage = 1; loadNomenclaturesFiltered()}"
              :options="brands"
          >
            <template #first>
              <b-form-select-option :value="null">Все</b-form-select-option>
            </template>
          </b-form-select>
        </div>
        <div class="d-flex mt-1 align-items-center">
          <label class="mr-1">Категория:</label>
          <b-form-select
              @change="function(val) {selectObject = val; currentPage = 1; loadNomenclaturesFiltered()}"
              :options="objects"
          >
            <template #first>
              <b-form-select-option :value="null">Все</b-form-select-option>
            </template>
          </b-form-select>
        </div>
      </div>
    </div>
    <b-table id="table_load_products"
             responsive
             :thead-tr-class="'text-nowrap'"
             :fields="getFields"
             show-empty
             :details-td-class="'p-0'"
             :items="getProducts"
    >
      <template #head(menu)="data">
        <div style="width: 100px">{{ data.label }}</div>
      </template>
      <template #head(name)="data">
        <div style="width: 200px">{{ data.label }}</div>
      </template>
      <template #head(sebes)="data">
        <div style="width: 150px">{{ data.label }}</div>
      </template>
      <template #head(storage)="data">
        <div style="width: 80px">{{ data.label }}</div>
      </template>
      <template #head(preparation)="data">
        <div style="width: 135px">{{ data.label }}</div>
      </template>
      <template #head(stockmp)="data">
        <div style="width: 50px">{{ data.label }}</div>
      </template>
      <template #head(wayMp)="data">
        <div style="width: 101px">{{ data.label }}</div>
      </template>
      <template #head(selectAll)="data">
        <div style="width: 20px; margin-left: 10px">
          <b-form-checkbox
              :checked="selectedProductsNmID.length > 0"
              :indeterminate="selectedProductsNmID.length > 0 && selectedProductsNmID.length !== products.length"
              @change="function(val) { val ? selectAllProducts() : removeAllProducts()}"
          />
        </div>
      </template>

      <template #cell(name)="data">
        <div style="width: 200px; overflow-x: hidden">
          <product-cell
              v-if="data.item.sizes"
              @openDetail="data.toggleDetails()"
              :productData="data.item"
          />
        </div>

      </template>
      <template #cell(sebes)="data">
        <div style="width: 200px" class="d-flex align-items-center">
          <b-form-input
              type="number"
              style="max-width: 70px"
              :value="data.item.sizes && data.item.sizes[0] ? data.item.sizes[0].averagePrice : 0"
              placeholder="Себестоимость"
              @blur="function(ev) {sendSebesC(data.item.nmID, ev.target.value)}"
          />
          <span class="btn-icon ml-1">
            <feather-icon v-if="getStateIcon === 'check_' + data.item.nmID" icon="CheckIcon"/>
            <b-spinner v-else-if="getStateIcon === 'load_' + data.item.nmID" small/>
          </span>
        </div>
      </template>
      <template #cell(storage)="data">
        <div style="width: 80px">
          {{ data.item.totalStorage || 0 }}
        </div>
      </template>
      <template #cell(preparation)="data">
        <div style="width: 80px">
          {{ data.item.totalPreparation || 0 }}
        </div>
      </template>
      <template #cell(wayMp)="data">
        <div style="width: 80px">
          {{ data.item.totalWayMp || 0 }}
        </div>
      </template>
      <template #cell(stockmp)="data">
        <div style="width: 80px">
          {{ data.item.totalStockmp || 0 }}
        </div>
      </template>
      <template #cell(menu)="data">
        <div class="cursor-pointer d-flex align-items-center justify-content-center m-auto" style="width: 18px"
             @click="showProductDetails(data.item.id)">
          <svg width="4" height="18" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="2" cy="2" r="2" fill="#979797"/>
            <circle cx="2" cy="9" r="2" fill="#979797"/>
            <circle cx="2" cy="16" r="2" fill="#979797"/>
          </svg>
        </div>
      </template>
      <template #cell(selectAll)="data">
        <div style="width: 32px; margin-left: 10px">
          <b-form-checkbox
              @change="function(val) { val ? selectProduct(data.item.nmID) : removeProduct(data.item.nmID)}"
              :checked="selectedProductsNmID.includes(data.item.nmID)"
          />
        </div>
      </template>
      <template #empty="scope">
        <h4 class="load_products load_products_process"
            v-if="!loadProductsProcess"
        >
          Список товаров загружается ...
        </h4>
        <h4 class="load_products load_products_process"
            v-else-if="loadProductsProcess === true"
        >
          Список товаров пуст
        </h4>
      </template>
      <template #row-details="data">
        <table-details-size
            :sizes="data.item.sizes"
        />
      </template>
    </b-table>
    <div class="block-pagination my-1">
      <b-row>
        <b-col
            cols="12">
          <b-pagination
              :total-rows="rows"
              v-model="currentPage"
              :per-page="pageSIze"
              @change="function(val) {if(currentPage !== val) {currentPage = val;  loadNomenclaturesFiltered()}}"
              first-number
              last-number
              align="center"
              prev-class="prev-item"
              next-class="next-item">
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

import {
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BLink,
  BFormSelectOption,
  BRow,
  BSpinner,
  BTable,
  BPagination
} from 'bootstrap-vue';

import ProductCell from "@/components/ProductCell";
import {mapActions, mapState} from "vuex";
import checkBoxCell from "@/components/checkBoxCell";
import TableDetailsSize from "@/components/product/TableDetailsSize";
import loadProductsFromMp from "@/components/product/LoadProductsFromMp";

export default {
  name: "TableNomenclatures",
  components: {
    checkBoxCell,
    ProductCell,
    BCard,
    BCol,
    BRow,
    BFormInput,
    BFormSelectOption,
    BFormCheckbox,
    BTable,
    BSpinner,
    BButton,
    BFormGroup,
    BFormSelect,
    TableDetailsSize,
    BPagination
  },

  data() {
    return {
      fields: [
        {
          key: 'selectAll',
        },
        {
          key: 'name',
          label: 'Наименование',
          sortable: true,
        },
        {
          key: 'sebes',
          label: 'Себес',
          sortable: true,
        },
        {
          key: 'storage',
          label: 'На ФФ ',
          sortable: true,
        },
        {
          key: 'preparation',
          label: 'Готовится на МП',
          sortable: true,
        },
        {
          key: 'wayMp',
          label: 'В пути на МП',
          sortable: true,
        },
        {
          key: 'stockmp',
          label: 'на МП',
          sortable: true,
        },
        {
          key: 'menu',
          label: '',
        },
      ],
      pageSIze: 10,
      currentPage: 1,
      stateIcon: 'wait',
      currentSebes: null,
      currentFind: '',
      selectBrand: null,
      selectObject: null,
    }
  },

  props: {
    blackFilterNmId: {
      type: Array,
      default() {
        return [];
      }
    },

    blackFilterFields: {
      type: Array,
      default() {
        return [];
      }
    },
  },

  computed: {
    getFields() {
      return this.fields.filter(field => !this.blackFilterFields.some(bField => bField === field.key))
    },

    getProducts() {
      return this.products.filter(prod => !this.blackFilterNmId.some(bNmId => bNmId === prod.nmID));
    },

    getStateIcon() {
      return this.stateIcon
    },

    rows() {
      return this.totalProducts
    },

    getCurrState() {
      return this.selectedProductsId.length > 0;
    },
    ...mapState('nomenclatures', ["brands", "objects", "products", "selectedProductsNmID", "loadProductsProcess", "totalProducts"])
  },

  methods: {
    showProductDetails(productId) {

    },

    async loadNomenclaturesFiltered() {
      await this.loadNomenclatures({
        page: this.currentPage,
        pageSize: this.pageSIze,
        subjectName: this.selectObject,
        brand: this.selectBrand,
        find: this.currentFind
      })
    },

    async sendSebesC(nmID, costPrice) {
      costPrice = +costPrice
      if (typeof costPrice === "number" && costPrice > 0) {
        this.stateIcon = 'load_' + nmID;
        console.log(this.getStateIcon)
        await this.sendSebes({nmID, costPrice});
        this.stateIcon = 'check_' + nmID;
        setTimeout(_ => {
          console.log(this.getStateIcon)
          this.stateIcon = 'wait'
        }, 2000)
        this.currentSebes = null;
      }
    },

    sendIdDetails(id) {
      this.$emit('details', id)
    },
    ...mapActions('products', ['setPage', 'sendSebes']),
    ...mapActions('nomenclatures', ["selectAllProducts", "clearsSelectNmId", "loadNomenclatures", "removeAllProducts", "selectProduct", "removeProduct"])
  },
  watch: {

  }
}
</script>

<style scoped>
.load_products {
  color: #d9d9d9;
  text-align: center;
  font-size: 44px;
  margin: 54px 0 18px 0;
}
</style>