<template>
  <div class="product-cell d-flex position-relative align-items-center">
    <b-form-checkbox class="mr-1">

    </b-form-checkbox>
    <img style="width: 60px" class="mr-1" :src="imgData.img" :alt="imgData.name">
    <div>
      <div style="font-size: 13px" class="font-weight-bold">{{ imgData.name }}</div>
      <div style="font-size: 12px">{{ imgData.article }}</div>
      <div style="font-size: 12px">{{ imgData.brand }}</div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex"
import {
  BFormCheckbox
} from 'bootstrap-vue'

export default {
  name: "ProductCellSelect",
  components: {
    BFormCheckbox,
  },
  props: {
    imgData: Object,
    detailsShowing: Boolean,
  },
  data() {
    return {}
  },
  methods: {
    openDetail() {
      this.$emit('openDetail');
    },
    ...mapActions('currentSupply', ['delProductFromSupply'])
  }
}

</script>

<style scoped>
.product-cell {
  width: 200px;
}
</style>