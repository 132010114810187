<template>
  <div class="container-load-products-popup">
    <b-tabs>
      <b-tab title="Wildberries">
        <wb-load-content/>
      </b-tab>
      <b-tab title="Ozon" disabled >

      </b-tab>
    </b-tabs>
    <div class="d-flex justify-content-between align-items-center">
      <b-button :disabled="!selectProductLoad.length" :variant="selectProductLoad.length ? 'primary' : ''" >Загрузить выбранные</b-button>
    </div>
  </div>
</template>

<script>

import {
  BTab,
  BTabs,
  BButton,
  VBModal,
} from 'bootstrap-vue';

import WbLoadContent from "@/components/product/WbLoadContent";

export default {
  name: "LoadProductsFromMp",
  components: {
    BTab,
    BTabs,
    BButton,
    WbLoadContent,
  },
  data() {
    return {
      selectProductLoad: []
    }
  },
  directives: {
    'b-modal': VBModal
  },
  methods: {
    showDetails(id) {
      this.currentDetailID = id;
      this.show = true
    },
  },
  computed: {},
}

</script>

<style scoped>

</style>