<template>
  <b-table
      class="purchasing-product-size-tables"
      responsive
      :fields="fields"
      first-number
      last-number
      show-empty
      thead-tr-class="text-nowrap"
      :items="sizes"
      :current-page="currentPage">
    <template #cell(size)="data">
      <div style="width: 250px; padding-left: 34px;" class="d-flex align-items-center">
        {{ `${data.item.techSize || ''} ${data.item.wbSize || ''} - ${data.item.skus[0] || ''}` }}
      </div>
    </template>
    <template #cell(amount)="data">
      <div style="width: 300px" class="d-flex align-items-center">
        <b-form-input
            class="input-amount-size"
            type="number"
            :value="data.item.amount"
            @input="function(amount) { setAmount(amount, data.item.chrtID); data.item._change = true }"
            @blur="function() {sendProducts(data.item)}"
        />
        <div v-if="data.item.lastPurchaseAmount" class="d-flex align-items-center">
          <feather-icon style="min-width: 14px" class="mx-1" icon="ClockIcon"/>
          <div class="mr-1"> {{ data.item.lastPurchaseAmount }}</div>
          <div v-if="trigger && data.item.amount">
            <b-badge v-if="data.item.diffAmount" :variant="data.item.diffAmount > 0 ? 'light-success' : 'light-danger'">
              {{ `${data.item.diffAmount > 0 ? '+' : ''} ${data.item.diffAmount} %` }}
            </b-badge>
          </div>
        </div>

      </div>
    </template>
    <template #cell(itemPrice)="data">
      <div style="width: 300px" class="d-flex align-items-center">
        <b-form-input
            class="input-amount-size"
            type="number"
            :value="data.item.price"
            @input="function(price) { setPrice(price, data.item.chrtID); data.item._changePrice = true }"
            @blur="function() {sendProducts(data.item)}"
        />
        <div v-if="data.item.lastPurchasePrice" class="d-flex align-items-center">
          <feather-icon style="min-width: 14px" class="mx-1" icon="ClockIcon"/>
          <div class="mr-1"> {{ data.item.lastPurchasePrice }}</div>
          <div v-if="trigger && data.item.price">
            <b-badge v-if="data.item.diffPercent"
                     :variant="data.item.diffPercent < 0 ? 'light-success' : 'light-danger'">
              {{ `${data.item.diffPercent > 0 ? '+' : ''} ${data.item.diffPercent} %` }}
            </b-badge>
          </div>
        </div>

      </div>
    </template>
    <template #cell(totalPrice)="data">
      <div style="width: 125px">
        <money-format :cost="data.item.price * data.item.amount"/>
      </div>
    </template>
  </b-table>
</template>
<script>

import {
  BButton,
  BImg,
  BCard,
  BCardText,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BLink,
  BModal,
  BNavItem,
  BPagination,
  BPopover,
  BBadge,
  BRow,
  BSpinner,
  BTab,
  BTable,
  BTabs,
  VBPopover
} from "bootstrap-vue";

import {mapActions, mapGetters, mapState} from "vuex";
import ProductCellSimple from "@/components/ProductCellSimple";
import PurchaseCreate from "@/components/procurement/PurchaseCreate";
import TablePurchaseProducts from "@/components/procurement/TablePurchaseProducts";
import cellShowDetail from "@/components/procurement/cellShowDetail";
import OrderStatus from "@/components/procurement/OrderStatus";
import PopUpSMV from "@/components/procurement/PopUpSMV";
import TableProductsPurchasesAdd from "@/components/procurement/TableProductsPurchasesAdd";

export default {
  name: "PurchasingProductSizeTables",
  directives: {
    'b-popover': VBPopover
  },
  data() {
    return {
      dateFrom: '',
      dateTo: '',
      selectEditPurchase: null,
      currentPage: 1,
      showDetails: false,
      showModal: false,
      fields: [
        {
          key: 'size',
          label: '',
        },
        {
          key: 'amount',
          label: 'Кол-во шт',
        },
        {
          key: 'itemPrice',
          label: 'Цена руб',
        },
        {
          key: 'totalPrice',
          label: 'Сумма закупки',
        },
      ],
      objectFilter: '',
      currentDetailID: null,
      show: false
    }
  },
  props: {
    sizes: {
      type: Array,
      default() {
        return []
      }
    },
    nmId: Number
  },

  methods: {
    sendProducts(item) {
      console.log(item._change && item.amount && item.price);
      if (item.amount || item.price) {
        item._change = false;
        item.nmId = this.nmId;
        this.saveProduct({
          procurementId: this.procurementId,
          products: [item]
        });
        this.clearSubSortToOperation();
      }

    },

    async setAmount(amount, chrtID) {
      await this.setAmountBaySize({amount, nmID: this.nmId, chrtID})
    },
    async setPrice(price, chrtID) {
      await this.setPriceBaySize({price, nmID: this.nmId, chrtID})
    },
    ...mapActions('purchaseItem', ['setAmountBaySize', 'setPriceBaySize', 'saveProduct', 'loadProductsPurchase']),
    ...mapActions('unitReport', ['clearSubSortToOperation']),
    clearCreatePurchase() {
      this.selectEditPurchase = null
      //this.$refs.creator.$destroy()
    },
    editPurchase(id) {
      this.selectEditPurchase = id;
      this.showModal = true
    },
  },

  computed: {
    procurementId() {
      return this.$route.params.id
    },
    ...mapState('purchaseItem', ['products', "trigger"]),
  },

  watch: {},

  components: {
    BImg,
    ProductCellSimple,
    TableProductsPurchasesAdd,
    BCard,
    BNavItem,
    BPopover,
    BDropdown,
    BDropdownItem,
    PurchaseCreate,
    TablePurchaseProducts,
    BTabs,
    BTab,
    BCardText,
    BBadge,
    BLink,
    BSpinner,
    cellShowDetail,
    BButton,
    BPagination,
    BFormDatepicker,
    BCol,
    BModal,
    BTable,
    BRow,
    BFormInput,
    BFormCheckbox,
    BFormGroup,
    BFormSelect,
    OrderStatus,
    PopUpSMV
  },
  async mounted() {

  },
}
</script>

<style scoped>

.input-amount-size {
  width: 100px;
}

</style>