<template>
  <div class="wb-load-content">
    <b-card class="statistic-products-wb">
      <b-row>
        <b-col>
          <b-media class="mb-1" vertical-align="top">
            <template #aside>
              <b-avatar
                  style="background-color: #EEECFD"
                  variant="primary"
                  size="50px"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <mask id="mask0_1331_63248" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="1" y="0"
                        width="22" height="24">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M13.3 0.599805L21.3 4.5998C22.4 5.0998 23 6.0998 23 7.1998V16.6998C23 17.8998 22.3 18.8998 21.3 19.3998L13.3 23.3998C12.9 23.5998 12.5 23.6998 12 23.6998C11.6 23.6998 11.1 23.5998 10.7 23.3998L2.7 19.3998C1.6 18.8998 1 17.7998 1 16.6998V7.1998C1 6.0998 1.6 5.0998 2.6 4.5998L10.6 0.599805C11.5 0.199805 12.5 0.199805 13.3 0.599805ZM12 2.1998C11.9 2.1998 11.7 2.1998 11.6 2.2998L4.2 5.9998L12 9.8998L19.8 5.9998L12.4 2.2998C12.3 2.1998 12.2 2.1998 12 2.1998ZM3.5 17.6998C3.2 17.4998 3 17.0998 3 16.7998V7.5998L11 11.5998V21.3998L3.5 17.6998ZM13 21.3998L20.4 17.6998C20.8 17.4998 21 17.1998 21 16.7998V7.5998L13 11.5998V21.3998Z"
                          fill="white"/>
                  </mask>
                  <g mask="url(#mask0_1331_63248)">
                    <rect width="24" height="24" fill="#7367F0"/>
                  </g>
                </svg>
              </b-avatar>
            </template>
            <h4 class="media-heading">
              123
            </h4>
            <b-card-text class="mb-0">
              Карточек товара
            </b-card-text>
          </b-media>
        </b-col>
        <b-col>
          <b-media class="mb-1"  vertical-align="top">
            <template #aside>
              <b-avatar
                  style="background-color: #EEECFD"
                  variant="light-success"
                  size="50px"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <mask id="mask0_1331_63296" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="1" y="2"
                        width="22" height="20">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M19 12C19 11.4 19.4 11 20 11C20.6 11 21 11.4 21 12V19C21 20.7 19.7 22 18 22H4C2.3 22 1 20.7 1 19V5C1 3.3 2.3 2 4 2H15C15.6 2 16 2.4 16 3C16 3.6 15.6 4 15 4H4C3.4 4 3 4.4 3 5V19C3 19.6 3.4 20 4 20H18C18.6 20 19 19.6 19 19V12ZM11.7 14.7L22.7 3.7C23.1 3.3 23.1 2.7 22.7 2.3C22.3 1.9 21.7 1.9 21.3 2.3L11 12.6L8.7 10.3C8.3 9.9 7.7 9.9 7.3 10.3C6.9 10.7 6.9 11.3 7.3 11.7L10.3 14.7C10.5 14.9 10.7 15 11 15C11.3 15 11.5 14.9 11.7 14.7Z"
                          fill="white"/>
                  </mask>
                  <g mask="url(#mask0_1331_63296)">
                    <rect width="24" height="24" fill="#28C76F"/>
                  </g>
                </svg>

              </b-avatar>
            </template>
            <h4 class="media-heading">
              250
            </h4>
            <b-card-text class="mb-0">
              Номенклатур
            </b-card-text>
          </b-media>
        </b-col>
        <b-col>
          <b-media class="mb-1"  vertical-align="top">
            <template #aside>
              <b-avatar
                  style="background-color: #EEECFD"
                  variant="light-danger"
                  size="50px"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <mask id="mask0_1331_63284" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="1" y="1"
                        width="22" height="22">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M1 12C1 18.1 5.9 23 12 23C18.1 23 23 18.1 23 12C23 5.9 18.1 1 12 1C5.9 1 1 5.9 1 12ZM3 12C3 7 7 3 12 3C17 3 21 7 21 12C21 17 17 21 12 21C7 21 3 17 3 12ZM13 12V8C13 7.4 12.6 7 12 7C11.4 7 11 7.4 11 8V12C11 12.6 11.4 13 12 13C12.6 13 13 12.6 13 12ZM12.7 16.7L12.7 16.7C12.5 16.9 12.3 17 11.9 17C11.6 17 11.4 16.9 11.2 16.7C11 16.5 10.9 16.3 10.9 16C10.9 15.8828 10.9343 15.8 10.9627 15.7314C10.9828 15.6828 11 15.6414 11 15.6C11 15.5 11.1 15.4 11.2 15.3C11.5 15 11.9 14.9 12.3 15.1C12.35 15.1 12.375 15.125 12.4 15.15C12.425 15.175 12.45 15.2 12.5 15.2C12.6 15.2 12.7 15.3 12.7 15.3C12.75 15.35 12.775 15.4 12.8 15.45C12.825 15.5 12.85 15.55 12.9 15.6C13 15.7 13 15.9 13 16C13 16.05 12.975 16.125 12.95 16.2C12.925 16.275 12.9 16.35 12.9 16.4C12.9 16.5 12.8 16.6 12.7 16.7Z"
                          fill="white"/>
                  </mask>
                  <g mask="url(#mask0_1331_63284)">
                    <rect width="24" height="24" fill="#EA5455"/>
                  </g>
                </svg>
              </b-avatar>
            </template>
            <h4 class="media-heading">
              0
            </h4>
            <b-card-text class="mb-0">
              С ошибкой
            </b-card-text>
          </b-media>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <div>
        <h3>
          Выберите товары
        </h3>
        <div style="min-width: 320px">
          Для удобного выбора используйте поиск. Введите любой из параметров товара, для фильтрации по этому параметру
        </div>
      </div>
      <div class="d-flex align-items-center mt-1" style="max-width: 260px;">
        <label class="mr-1">Поиск:</label>
        <b-form-input
            placeholder="Поиск"
            type="text"
            class="d-inline-block"
            @input="findFilter"
        />
      </div>
      <b-table class="table-load-product-wb mt-1"
               :fields="fields"
               responsive
               :thead-tr-class="'text-nowrap'"
               :items="productsWidthWB"
      >
        <template #head(name)="data">
          <div class="d-flex align-items-center">
            <b-form-checkbox class="mr-1"/>
            <span>{{ data.label.toUpperCase() }}</span>
          </div>
        </template>
        <template #cell(name)="data">
          <product-cell-select
              :imgData="{
            id: data.item.id,
            img: data.item.img[0],
            name: data.item.name,
            article: data.item.article,
            brand: data.item.brand,
          }"
          />
        </template>
        <template #cell(volume)="data">
          {{ `${data.item.parameters.weight} | ${data.item.parameters.length} X ${data.item.parameters.width} X ${data.item.parameters.height}`}}
        </template>
      </b-table>
    </b-card>

  </div>
</template>

<script>

import {
  BTab,
  BTabs,
  BTable,
  BCard,
  BMedia,
  BRow,
  BFormCheckbox,
  BFormInput,
  BCol,
  BImg,
  BCardText,
  BAvatar,
  VBModal,
} from 'bootstrap-vue'

import {mapActions, mapState} from "vuex";
import ProductCellSelect from "@/components/ProductCellSelect";

export default {
  name: "WbLoadContent",
  components: {
    ProductCellSelect,
    BTab,
    BTabs,
    BFormInput,
    BCard,
    BFormCheckbox,
    BMedia,
    BRow,
    BTable,
    BCardText,
    BImg,
    BCol,
    BAvatar,
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Наименование',
        },
        {
          key: 'price',
          label: 'Цена ',
          sortable: true,
        },
        {
          key: 'volume',
          label: 'Габариты',
        },
        {
          key: 'object',
          label: 'Категория',
          sortable: true,
        },
      ],
      findFilter: '',
    }
  },
  directives: {
    'b-modal': VBModal
  },
  methods: {
    showDetails(id) {
      this.currentDetailID = id;
      this.show = true
    },
  },
  computed: {
    ...mapState('products', ['productsWidthWB'])
  },
}

</script>

<style scoped>

</style>