<template>
  <div class="table-products-purchases">
    <div class="mb-2 d-flex flex-wrap justify-content-between align-items-start">
      <div v-if="currentPurchase">
        <div class="d-flex align-items-start">
          <h4 v-if="currentPurchase">{{ `#${currentPurchase.id} ${currentPurchase.name}` }}</h4>
          <b-badge class="ml-1" :style="{'background-color': statusColor[currentPurchase.status]}"
          > {{ currentPurchase.status }}
          </b-badge>
        </div>
        <div
            style="font-size: 14px; font-weight: normal">
          {{ formatDate(currentPurchase.create_at) }}
        </div>
      </div>

      <div class="d-flex justify-content-between">
        <b-button
            class="mr-1"
            v-if="existItems"
            @click="$router.push('/procurement')"
            variant="outline-primary"
        >
          <feather-icon
              icon="ArrowLeftIcon"
              class="mr-50"
          />
          <span class="align-middle">Назад</span>
        </b-button>
        <b-button
            v-if="existItems"
            v-b-modal.add_products_to_purchase
            variant="outline-primary"
        >
          <feather-icon
              icon="PlusIcon"
              class="mr-50"
          />
          <span class="align-middle">Добавить товар</span>
        </b-button>
        <b-button
            v-else
            :disabled="true"
            v-b-modal.add_products_purchase
            variant="outline-secondary"
        >
          <feather-icon
              icon="CheckIcon"
              class="mr-50"
          />
          <span class="align-middle">Все товары добавлены</span>
        </b-button>
      </div>

    </div>
<!--    <component v-bind:is="currentTabComponent"></component>-->
    <b-table
        responsive
        :fields="fields"
        first-number
        last-number
        show-empty
        thead-tr-class="text-nowrap"
        :details-td-class="'p-0'"
        :items="getProductsInPurchase"
        :current-page="currentPage"
        :per-page="10">
      <template #cell(name)="data">
        <div style="width: 250px" class="d-flex align-items-center"
             @click="function() { if(!data.item._showDetails) closeAll(); data.item._showDetails = !data.item._showDetails;}"
        >
          <cell-show-detail
              :setState="data.item._showDetails"
          />
          <b-img class="mr-1" :src="data.item.url" height="70px"/>
          <div>
            <div>{{ data.item.title }}</div>
            <div style="font-size: 12px">{{ data.item.vendorCode }}</div>
            <div style="font-size: 12px">{{ data.item.brand }}</div>
          </div>
        </div>
      </template>
      <template #cell(amount)="data">
        <div style="width: 300px"
             class="d-flex align-items-center">
          <money-format :cost="trigger ? data.item.sizes.reduce((sum, size) => sum + +size.amount, 0) : ''"/>
        </div>
      </template>
      <template #cell(itemPrice)="data">
        <div class="d-flex align-items-center" style="width: 300px;">
          <money-format :cost="data.item.sizes.reduce((sum, size) => sum + +size.price, 0)"/>
        </div>
      </template>
      <template #cell(totalPrice)="data">
        <div style="width: 125px" class="d-flex align-items-center">
          <money-format :cost="data.item.sizes.reduce((sum, size) => sum + +size.price * size.amount, 0)"/>
        </div>
      </template>
      <template #row-details="data">
        <purchasing-product-size-tables
            :sizes="data.item.sizes"
            :nmId="data.item.nmID"
        />
      </template>
    </b-table>
    <b-modal class="add-products-to-shipment"
             id="add_products_to_purchase"
             hide-header
             hide-footer
             modal-class="modal-primary"
             centered
             size="xl"
    >
      <h4 class="my-1">Выберите товары для закупки</h4>
      <table-nomenclatures
          :black-filter-nm-id="getNmIdInPurchase"
          :black-filter-fields="['menu', 'sebes']"
      />
    </b-modal>
  </div>
</template>


<script>
import {
  BButton,
  BImg,
  BBadge,
  BCard,
  BCardText, BCol,
  BDropdown,
  BDropdownItem, BFormCheckbox, BFormDatepicker, BFormGroup, BFormInput, BFormSelect,
  BLink, BModal,
  BNavItem, BPagination,
  BPopover, BRow, BSpinner,
  BTab, BTable,
  BTabs,
  VBPopover
} from "bootstrap-vue";
import {mapActions, mapGetters, mapState} from "vuex";
import ProductCellSimple from "@/components/ProductCellSimple";
import PurchaseCreate from "@/components/procurement/PurchaseCreate";
import TablePurchaseProducts from "@/components/procurement/TablePurchaseProducts";
import cellShowDetail from "@/components/procurement/cellShowDetail";
import OrderStatus from "@/components/procurement/OrderStatus";
import PopUpSMV from "@/components/procurement/PopUpSMV";
import TableProductsPurchasesAdd from "@/components/procurement/TableProductsPurchasesAdd";
import PurchasingProductSizeTables from "@/components/procurement/PurchasingProductSizeTables";
import TableNomenclatures from "@/components/product/TableNomenclatures.vue";

export default {
  name: "PurchaseItem",
  components: {
    BImg,
    BBadge,
    TableNomenclatures,
    ProductCellSimple,
    TableProductsPurchasesAdd,
    BCard,
    PurchasingProductSizeTables,
    BNavItem,
    BPopover,
    BDropdown,
    BDropdownItem,
    PurchaseCreate,
    TablePurchaseProducts,
    BTabs,
    BTab,
    BCardText,
    BLink,
    BSpinner,
    cellShowDetail,
    BButton,
    BPagination,
    BFormDatepicker,
    BCol,
    BModal,
    BTable,
    BRow,
    BFormInput,
    BFormCheckbox,
    BFormGroup,
    BFormSelect,
    OrderStatus,
    PopUpSMV
  },
  directives: {
    'b-popover': VBPopover
  },

  data() {
    return {
      dateFrom: '',
      dateTo: '',
      selectEditPurchase: null,
      currentPage: 1,
      showDetails: false,
      statusColor: {
        'План': '#34C3E3',
        'В производстве': '#C369DA',
        'В пути': '#E19D4E',
        'Приемка': '#5293A1',
        'Принято': '#4DCEA7',
        'Возврат': '#F38374',
      },
      showModal: false,
      fields: [
        {
          key: 'name',
          label: 'Товар',
        },
        {
          key: 'amount',
          label: 'Кол-во шт',
        },
        {
          key: 'itemPrice',
          label: 'Цена руб',
        },
        {
          key: 'totalPrice',
          label: 'Сумма закупки',
        },
      ],
      objectFilter: '',
      currentDetailID: null,
      show: false
    }
  },

  methods: {
    ...mapActions('purchase', ["loadListPurchase"]),
    ...mapActions('purchaseItem', ['loadProductsPurchase', "clearProductsToAdd", "prependProducts"]),
    formatDate(date) {
      date = new Date(date)
      let day = String(date.getDate()).padStart(2, "0");
      let month = String(date.getMonth()).padStart(2, "0");
      let year = date.getFullYear();
      return `${day}-${month}-${year}`
    },
    closeAll() {
      this.products.forEach(item => item._showDetails = false);
    },
    clearCreatePurchase() {
      this.selectEditPurchase = null
      //this.$refs.creator.$destroy()
    },
    editPurchase(id) {
      this.selectEditPurchase = id;
      this.showModal = true
    },
  },

  computed: {
    getProductsInPurchase() {
      return this.products
    },

    getNmIdInPurchase() {
      return this.products.map(prod=>prod.nmID);
    },

    existItems() {
      return !!this.getNewProducts.length
    },
    currentPurchase() {
      return this.purchases.find(item => +item.id === +this.$route.params.id);
    },

    ...mapState('purchase', ['purchases']),
    ...mapState('purchaseItem', ['products', "trigger", "popUpState"]),
    ...mapGetters('purchaseItem', ['getNewProducts']),
  },

  watch: {},

  async mounted() {
    if (!this.purchases.length) await this.loadListPurchase();
    await this.loadProductsPurchase({procurementId: this.$route.params.id})
    await this.clearProductsToAdd();
  },
}
</script>

<style scoped>

.date-order {
  text-align: center;
}

.time-order {
  text-align: center;
}

.order-status-icon {

}

</style>